export const projects = [
    {
      title: "MLB Videos",
      subtitle: "Python",
      description:
        "Small bot pipeline built to analyze MLB Statcast data, download clips, create compilations & post to Twitter & YouTube.",
      image: "./project-1.gif",
      link: "https://github.com/coperyan/mlb_videos",
    },
    {
      title: "Personal Budget Builder",
      subtitle: "Python, Excel",
      description:
        "Customize & generate your personal budget tool using Python & Excel",
      image: "./budget_gif.gif",
      link: "https://github.com/coperyan/personal-budget-builder",
    },
    // {
    //   title: "Python Modules",
    //   subtitle: "Python",
    //   description:
    //     "Reusable modules shared across projects - working with everything from Azure/Google Cloud to Microsoft Excel to file io/path",
    //   image: "./python-image.png",
    //   link: "https://github.com/coperyan/python-modules",
    // },
    {
      title: "MSSQL to BigQuery",
      subtitle: "Python",
      description:
        "Python API to automate ingestion of data from MSSQL to BigQuery",
      image: "./mssql_to_bq.png",
      link: "https://github.com/coperyan/mssql-to-bigquery",
    },
    {
      title: "C# Microsoft Graph App",
      subtitle: "C#, MS Graph API",
      description:
        "A .NET Core Console App using Azure authentication to send (Outlook) emails using the Microsoft Graph API.",
      image: "./Csharp.svg",
      link: "https://github.com/coperyan/MicrosoftGraph-Emails",
    },
  ];

  export const experiences = [
    {
      id: 1,
      companyName: "Elevate Analytics",
      title: "Co-Founder",
      dateRange: "Jan 2023 - Present",
      description: ""
    },
    {
      id: 2,
      companyName: "Driven Brands",
      title: "Director, Analytics",
      dateRange: "Mar 2023 - Present",
      description: "I currently lead business intelligence & data operations initiatives, supporting over 1,000 locations & $600M in annual revenue"
    },
    {
      id: 3,
      companyName: "Driven Brands",
      title: "Manager, Analytics",
      dateRange: "Jan 2020 - Mar 2023",
      description: "Led overhaul of pricing optimization strategy, improving average selling price by 50%, maintained conversion rates and delivered $40M in annual revenue impact."
    },
    {
      id: 4,
      companyName: "Uber",
      title: "Data Engineer (Consultant)",
      dateRange: "2019 - 2020",
      description: ""
    },
    {
      id: 5,
      companyName: "Driven Brands",
      title: "Senior Analyst",
      dateRange: "Jan 2020 - Mar 2023",
      description: ""
    },
    {
      id: 6,
      companyName: "Driven Brands",
      title: "Data Analyst",
      dateRange: "Jan 2015 - Jan-2017",
      description: ""
    }
  ];
  
  export const carouselImages = [
    {
      id: 1,
      src: "./carousel_images/mccloud1.jpg",
      alt: "mccloud1",
      title: "McCloud, CA",
      description: 
        "",
    },
    {
      id: 2,
      src: "./carousel_images/mccloud2.jpg",
      alt: "mccloud2",
      title: "McCloud, CA",
      description: 
        "",
    },
    {
      id: 3,
      src: "./carousel_images/sierraButtes.jpg",
      alt: "sierraButtes",
      title: "Lost Sierra, CA",
      description: 
        "",
    },
    {
      id: 4,
      src: "./carousel_images/grouseRidge.jpg",
      alt: "grouseRidge",
      title: "Tahoe National Forest, CA",
      description: 
        "",
    },
    {
      id: 5,
      src: "./carousel_images/washingtonYuba.jpg",
      alt: "washingtonYuba",
      title: "South Yuba River, CA",
      description: 
        "",
    },
  ];
  
  export const skills = [
    "SQL",
    "Python",
    ".NET",
    "GCP/Azure",
    "Airflow/Spark",
    "Qlik/Tableau",
    "Data Modeling",
    "Data Engineering",
    "Data Visualization",
    "Project Management"
  ];
  