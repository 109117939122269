import React from "react";
import Slider from "react-slick";
import { carouselImages } from "../data";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

export default function Freetime() {
    return (
        <section id="freetime">
            <div className="container px-5 py-10 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                Outside of Data
              </h1>
              <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                I love the outdoors - some of my favorite hobbies are mountain biking, hiking, and swimming.
              </p>
              <br></br>
              <Slider {...settings}>
                        {carouselImages.map((item) => (
                            <div key={item.id}>
                            <img src={item.src} alt={item.alt} className="img" />
                            <h3>{item.title}</h3>
                            </div>
                        ))}

                    </Slider>
                    
            </div>
            </div>
        </section>
    );
}