import { UserIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Button } from "rsuite";
import { experiences } from "../data";
//import "rsuite/dist/rsuite.min.css";

export default function Experience() {
    return (
        <section id="experience">
          <div className="container px-5 py-10 mx-auto">
            <div className="text-center mb-20">
              <UserIcon className="w-10 inline-block mb-4" />
              <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                Experience
              </h1>
              {/* <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi sit
                ipsa delectus eum quo voluptas aspernatur accusantium distinctio
                possimus est.
              </p> */}
            </div>
            <div class="flex flex-col w-full mb-20">
              {experiences.map((experience) => (
                <div key={experience.id} className="pb-10 w-full">
                {/* <div className="bg-gray-800 rounded flex p-4 h-full items-center"> */}
                  <div className="pb-1 title-font text-3xl font-medium text-white xl:w-2/4 lg:w-3/4 mx-auto">
                    {experience.companyName}
                  </div>
                  <div className="pb-3 text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    {experience.title}&nbsp;&nbsp;|&nbsp;&nbsp;{experience.dateRange}
                    </div>
                  <div className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    {experience.description}
                  </div>

                {/* </div> */}
              </div>
              ))}
            </div>
            <div className="text-center mb-20">
                <a href="./resume/resume.pdf" download="Ryan_Cope_Resume.pdf">
                
                {/* <p>Download Resume</p> */}
                <button type="button" class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"><u>Download Resume</u></button>
                </a>
            </div>
          </div>
        </section>
      );
}