import React from "react";
import { AiFillGithub,} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

export default function Footer() {
    let date = new Date();
    let year = date.getFullYear();
  return (
     <div className="bg-gray-800 md:sticky bottom-0 p-3 z-10">
      {/* <div className="container justify-items-center text-center mx-auto flex flex-wrap p-5 flex-col md:flex-row"> */}
        <div className="text-center font-medium text-white mb-4 md:mb-0">
          <p>Copyright © {year} Ryan Cope</p>
          </div>
          
        
        {/* <div className="w-1/2 justify-items-end mx-auto flex flex-wrap p-5 flex-col md:flex-row">
            <div className="pr-4 justify-self-end">
            <a href="https://www.github.com/coperyan" className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"><AiFillGithub /></a>
            </div>
            <div className="pr-4 justify-self-end">
            <a href="https://www.linkedin.com/in/coperyan" className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"><FaLinkedinIn /></a>
            </div>
        </div> */}
      {/* </div> */}
      </div>
  );
}